import API_ENDPOINT from "../../constants/api_endpoint.constant";
import axios from '../../utils/axiosService';
import { getToken } from '../../utils/token';

export default class EditService {
    getElement(merchantNo) {
        axios.setHeader("Authorization", `Bearer ${getToken()}`);
        axios.setHeader("Content-Type", `application/json`);
        return axios.get(`${API_ENDPOINT.MERCHANT}/${btoa(merchantNo)}`);
    };

    // update({ merchant, agxMerchantNo }) {
    //     return axios.put(`${API_ENDPOINT.MERCHANT}/${agxMerchantNo}`, merchant);
    // }
}