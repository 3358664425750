import { createAsyncThunk } from "@reduxjs/toolkit";
import { authService } from "../../services/index.service";
import { setToken, removeToken } from '../../utils/token';
import axios from '../../utils/axiosService';

export const signIn = createAsyncThunk(
    "auth/signIn",
    async (payload, thunkAPI) => {
        const user = payload;
        try {
            const { data } = await authService.signIn({
                username: user.username,
                password: user.password,
            });
            removeToken();
            setToken(data.token);
            axios.setHeader("Authorization", `Bearer ${data.token}`);
            axios.setHeader("Content-Type", `application/json`);
            return data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
)

export const signUp = createAsyncThunk(
    "auth/signUp",
    async (payload, thunkAPI) => {
        const info = payload;
        try {
            const { data } = await authService.signUp({
                id: null,
                username: info.username,
                email: info.email,
                password: info.password,
                memberType: info.memberType
            });
            removeToken();
            setToken(data?.data?.token);
            axios.setHeader("Authorization", `Bearer ${data?.data?.token}`);
            axios.setHeader("Content-Type", `application/json`);
            return data?.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
)

// export const changePassWord = createAsyncThunk(
//     "auth/changeInfo",
//     async (payload, thunkAPI) => {
//         const param = payload;
//         try {
//             const { data } = await authService.changePassword(param);
//             return data;
//         } catch (error) {
//             return thunkAPI.rejectWithValue(error);
//         }
//     }
// )

export const signOut = createAsyncThunk(
    "auth/signOut",
    async (payload, thunkAPI) => {
        try {
            authService.logout();
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
)