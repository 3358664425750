import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import './index.css';
import OnlineLogoHeder from '../../../../utils/image/Choqi_Pay_online_logo.png';
import useAuth from '../../../../hooks/useAuth';
import { authSelector } from '../../../../redux/auth/selector';
import { appSelector } from '../../../../redux/app/selector';
import { applicationSelector } from '../../../../redux/application/selector';
import { ROLE_MEMBER } from "../../../../constants/common.constant";

function Header() {
    const { agxStoreName, user, lastSuccessfulLogin } = useSelector(authSelector);
    const { merchant } = useSelector(applicationSelector);
    const { urlBanner } = useSelector(appSelector);
    const { handleSignOut } = useAuth();

    const handleClickSignOut = () => {
        handleSignOut();
    }

    return (
        <div id='choqi-header' className='navbar navbar-inverse navbar-static-top' role='navigation'>
            <div className='container'>
                <div className='navbar-header'>
                    <div className='visible-sm-block visible-md-block visible-lg-block visible-xs-block navbar-brand'>
                        <div className='xrm-editable-html xrm-attribute' data-languagecontext='日本語'>
                            <div className='xrm-attribute-value'>
                                <Link to={urlBanner ? urlBanner : '/home'}><img id='img-banner' style={{ margin: '-6px 0px' }}
                                    src={OnlineLogoHeder}
                                    alt='チョキペイ online' /></Link>
                            </div>
                        </div>
                    </div>
                    <button type='button' className='navbar-toggle collapsed' title='ナビゲーションの切り替え' data-toggle='collapse'
                        data-target='#navbar' aria-expanded='false' onClick={() => { }}>
                        <span className='sr-only'>ナビゲーションの切り替え</span>
                        <span className='icon-bar'></span>
                        <span className='icon-bar'></span>
                        <span className='icon-bar'></span>
                    </button>
                </div>
                {(user?.id?.length > 0 && user?.role?.length > 0) ? user?.role[0] === ROLE_MEMBER ?
                    <div id='navbar' className='navbar-collapse' style={{ flexBasis: '35%', flexGrow: '0', justifyContent: 'flex-end', textAlign: 'right' }}>
                        <div className='navbar-right menu-bar ' data-weblinks-maxdepth='2'>
                            <ul className='nav navbar-nav weblinks' role={'menubar'}>
                                <li id='dropdown-menu-header' className='dropdown' role='none'>
                                    <div className='text-right' style={{ display: 'flex', alignItems: 'center' }}>
                                        <div className='dropdown-menu-text' style={{ marginRight: '30px', color: '#fff' }}>
                                            <div className='header-display-agxStoreName-fullName'>
                                                {(agxStoreName ? agxStoreName : merchant?.agxStoreName ? merchant?.agxStoreName : '') + ' ' + (user?.lastName ? user?.lastName : '') + ' ' + (user?.firstName ? user?.firstName : '')}
                                                <span className="tooltipText">{(agxStoreName ? agxStoreName : merchant?.agxStoreName ? merchant?.agxStoreName : '') + ' ' + ((user?.lastName ? user?.lastName : '') + ' ' + (user?.firstName ? user?.firstName : ''))}</span>
                                            </div>
                                            <div>前回ログイン日時： {lastSuccessfulLogin}</div>
                                        </div>
                                        <div className='dropdown'>
                                            <button id='btn-dropdown' type='button' className='dropdown-toggle' data-toggle='dropdown'>
                                                メニュー
                                            </button>
                                            <ul className='dropdown-menu' role='menu'>
                                                <li role='none'>
                                                    {/* <Link to='/account'><span>ログイン情報</span></Link> */}
                                                    <Link to='/profile'><span>ログイン情報</span></Link>
                                                </li>
                                                <li className='divider' role='separator' aria-hidden='true'></li>
                                                <li role='none'>
                                                    <a href='https://choqi.co.jp/choqipay/faq/faq_list4.html' target='_blank' rel="noreferrer" ><span>よくあるご質問</span></a>
                                                </li>
                                                <li className='divider' role='separator' aria-hidden='true'></li>
                                                <li role='none'>
                                                    <a href={process.env.REACT_APP_URL_CONTACT} target='_blank'><span>お問い合わせ</span></a>
                                                </li>
                                                <li className='divider' role='separator' aria-hidden='true'></li>
                                                <li role='none'>
                                                    <span onClick={handleClickSignOut}>ログアウト</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div> : null : null}
            </div>
        </div>
    );
}

export default Header;