import API_ENDPOINT from "../../constants/api_endpoint.constant";
import axios from '../../utils/axiosService';
import { getToken } from '../../utils/token';

axios.setHeader("Authorization", `Bearer ${getToken()}`);
axios.setHeader("Content-Type", `application/json`);
export default class DepositService {
    
    getDateFromAgxPaymentManagement() {
        return axios.get(`${API_ENDPOINT.AGX_PAYMENT_MANAGEMENT}/user/date`);
    };
    getElementStoreDepositDetail(agxMerchantNo, transactionType, datetime) {
        return axios.get(`${API_ENDPOINT.AGX_PAYMENT_DETAIL}/store-deposit-detail?merchantNo=${agxMerchantNo}&transactionType=${transactionType}&datetime=${datetime}`);
    };
    getDepositData(merchantNo, agxPaymentDate) {
        return axios.get(`${API_ENDPOINT.DEPOSIT}?agxMerchantNo=${btoa(merchantNo)}&agxPaymentDate=${agxPaymentDate}`);
    };
    getSummaryMonthlyData(agxMerchantNo) {
        return axios.get(`${API_ENDPOINT.SUMMARY_MONTHLY}?merchantNo=${btoa(agxMerchantNo)}`);
    };
    getSummaryData(agxMerchantNo) {
        return axios.get(`${API_ENDPOINT.SUMMARY}?merchantNo=${btoa(agxMerchantNo)}`);
    };
    getDataStoreDepositDetail(agxMerchantNo, datetime) {
        return axios.get(`${API_ENDPOINT.STORE_DEPOSIT_DETAIL}?merchantNo=${btoa(agxMerchantNo)}&paymentDate=${datetime}`);
    };
}