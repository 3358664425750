import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { signIn, signUp, signOut } from '../redux/auth';
import useClearState from '../hooks/useClearState';

const useAuth = () => {
    const dispatch = useDispatch();
    const history = useNavigate();
    const {handleClearState} = useClearState();

    const handleSignIn = useCallback((username, password) => () => {
        dispatch(signIn({ username, password }));
    }, [dispatch]);

    const handleSignUp = useCallback((username, email, password, memberType) => {
        dispatch(signUp({username, email, password, memberType}));
    }, [dispatch]);

    const handleSignOut = useCallback(() => {
        dispatch(signOut());
        handleClearState();
        history('/signin', {replace: true});
    }, [dispatch, history]);

    return {
        handleSignIn,
        handleSignUp,
        handleSignOut
    }
}

export default useAuth;